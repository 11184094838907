// i18next-extract-mark-ns-start not-found

import * as React from 'react'
import { Link, useTranslation } from 'gatsby-plugin-react-i18next'
import Layout from 'src/components/layout'
import Helmet from 'react-helmet'
import Section from 'src/components/typography/Section'
import P from 'src/components/typography/Paragraph'
import PageTitle from 'src/components/typography/PageTitle'
import ContentPageContainer from 'src/components/layout/ContentPageContainer'
import Article from 'src/components/typography/Article'
import SemiRoundedButton, {
  ButtonSizes,
} from 'src/components/ui/SemiRoundedButton'
import { graphql } from 'gatsby'

const NotFoundPage = () => {
  const { t } = useTranslation('not-found')
  return (
    <>
      <Helmet>
        <title>The IFAB - Page not found</title>
      </Helmet>
      <Layout>
        <ContentPageContainer className="min-h-screen">
          <PageTitle>{t('Page Not \n Found')}</PageTitle>
          <Article>
            <Section>
              <>
                <P className="text-center">
                  {t('Sorry, the page you requested cannot be found.')}
                </P>
                <div className="text-center mt-24">
                  <SemiRoundedButton size={ButtonSizes.medium}>
                    <Link to="/">{t('Back to home')}</Link>
                  </SemiRoundedButton>
                </div>
              </>
            </Section>
          </Article>
        </ContentPageContainer>
      </Layout>
    </>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default NotFoundPage
